<template>
  <div :style="cssVars">
    <vue-editor
      :style="cssVars"
      ref="quillEditor"
      :customModules="customModulesForEditor"
      :editorOptions="editorSettings"
      :editorToolbar="customToolbar"
      v-model="_text"
    >
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'
export default {
  mounted() {
    const headingButtons = document.querySelectorAll('button.ql-header')
    Array.from(headingButtons).forEach(item => {
      const Attribute = item.getAttribute('value')
      if (Attribute == 3) {
        item.innerText = 'h3'
      }
      if (Attribute == 4) {
        item.innerText = 'h4'
      }
    })
  },
  name: 'PostEditor',
  components: { VueEditor },
  props: {
    data: {
      type: String,
      default: '',
      required: true
    },
    height: {
      type: String,
      default: '400px'
    },
    headings: {
      type: Object,
      required: false
    }
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        'min-height': this.height
      }
    },
    _text: {
      get: function() {
        return this.data
      },
      set: function(newValue) {
        return this.$emit('update:data', newValue)
      }
    }
  },
  data() {
    return {
      customModulesForEditor: [{ alias: 'imageResize', module: ImageResize }],
      editorSettings: {
        modules: {
          imageResize: {}
        }
      },
      customToolbar: [
        ['bold', 'underline'],
        [{ header: this.headings?.heading1 ? this.headings.heading1 : 2 }],
        [{ header: this.headings?.heading2 ? this.headings?.heading2 : 3 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [
          {
            header: [
              this.$route.name !== 'InternshipPostCreate' &&
                this.$route.name !== 'InternshipPostEdit' &&
                2,
              3,
              4,
              5,
              6,
              false
            ]
          }
        ],
        [
          {
            color: [
              '#e60000',
              '#ff9900',
              '#ffff00',
              '#008a00',
              '#0066cc',
              '#9933ff',
              '#ffffff',
              '#facccc',
              '#ffebcc',
              '#ffffcc',
              '#cce8cc',
              '#cce0f5',
              '#ebd6ff',
              '#f06666',
              '#ffff66',
              '#66b966',
              '#66a3e0',
              '#c285ff',
              '#888888',
              '#a10000',
              '#b26b00',
              '#b2b200',
              '#006100',
              '#0047b2',
              '#6b24b2',
              '#444444',
              '#5c0000',
              '#663d00',
              '#666600',
              '#003700',
              '#002966',
              '#3d1466',
              '#28AFA6',
              '#AA158B',
              '#00F',
              '#000',
              '#FFF'
            ]
          },
          {
            background: [
              '#e60000',
              '#ff9900',
              '#ffff00',
              '#008a00',
              '#0066cc',
              '#9933ff',
              '#ffffff',

              '#facccc',
              '#ffebcc',
              '#ffffcc',
              '#cce8cc',
              '#cce0f5',
              '#ebd6ff',
              '#f06666',

              '#ffff66',
              '#66b966',
              '#66a3e0',
              '#c285ff',
              '#888888',
              '#a10000',
              '#b26b00',

              '#b2b200',
              '#006100',
              '#0047b2',
              '#6b24b2',
              '#444444',
              '#5c0000',
              '#663d00',

              '#666600',
              '#003700',
              '#002966',
              '#3d1466',
              '#28AFA6',
              '#AA158B',
              '#00F',
              '#000',
              '#FFF'
            ]
          }
        ],
        //Removed font dropdown
        [{ align: [] }],
        ['link', 'image'] //Removed Video
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.ql-container,
.ql-editor {
  min-height: inherit !important;
}
::v-deep {
  .ql-container,
  .ql-editor {
    min-height: inherit !important;
  }
  .ql-tooltip {
    left: 0 !important;
    margin-left: 10px !important;
  }
  #quill-container {
    overflow: scroll;
    height: 410px;
  }
  #quill-container .ql-editor.ql-blank {
    cursor: auto;
  }

  #quill-container .ql-editor {
    cursor: move;
  }
}
</style>
