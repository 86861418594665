var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('div',{staticClass:"mx-16 py-12"},[_c('div',{staticClass:"d-flex justify-end"},[(_vm.singleInternship)?_c('span',{staticClass:"font-14px text-b8b8b8 mr-3"},[_vm._v(_vm._s(_vm._f("moment")(_vm.singleInternship.created_at,'YYYY/MM/DD'))+" 公開")]):_vm._e(),(_vm.singleInternship)?_c('span',{staticClass:"font-14px text-b8b8b8"},[_vm._v(_vm._s(_vm._f("moment")(_vm.singleInternship.updated_at,'YYYY/MM/DD'))+" 更新")]):_vm._e()]),(_vm.sectionTitle)?_c('div',{staticClass:"font-20px font-400"},[_vm._v(" "+_vm._s(_vm.sectionTitle)+" ")]):_vm._e(),_vm._l((_vm.basicInformation),function(field,index){return _c('v-row',{key:index,staticClass:"my-3",class:field.row_class,attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-1",class:field.col_class_left,attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[(field.type == 'text')?[_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-14px"},[_vm._v(" "+_vm._s(field.label)+" "),(field.rules && field.rules.includes('required'))?_c('span',{staticClass:"font-12px error--text ml-2"},[_vm._v("必須")]):_vm._e()]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" mt-1",class:{
                    'font-18px':
                      field.placeholder ===
                        '企業ID、または企業名フリガナを入力してください' ||
                      field.placeholder === 'タイトルを入力してください',
                    'font-14px':
                      field.placeholder !==
                        '企業ID、または企業名フリガナを入力してください' ||
                      field.placeholder !== 'タイトルを入力してください'
                  },attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"single-line":"","color":"#13ABA3","outlined":"","dense":"","placeholder":field.placeholder},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),(field.counter)?_c('div',{staticClass:"d-flex justify-end font-14px text-grey"},[_vm._v(" "+_vm._s(field.value ? field.value.length : 0)+" / "+_vm._s(field.counterValue)+" ")]):_vm._e()]}}],null,true)})],1)]:_vm._e(),(field.type == 'textarea')?[_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-14px"},[_vm._v(" "+_vm._s(field.label)+" "),(field.rules && field.rules.includes('required'))?_c('span',{staticClass:"font-12px error--text ml-2"},[_vm._v("必須")]):_vm._e()]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{staticClass:"font-16px mt-1",attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"single-line":"","color":"#13ABA3","outlined":"","dense":"","placeholder":field.placeholder},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),(field.counter)?_c('div',{staticClass:"d-flex justify-end font-14px text-grey"},[_vm._v(" "+_vm._s(field.value ? field.value.length : 0)+" "+_vm._s(field.counterValue)+" ")]):_vm._e()]}}],null,true)})],1)]:_vm._e(),(field.type == 'dropdown')?[_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-14px"},[_vm._v(" "+_vm._s(field.label)+" "),(field.rules && field.rules.includes('required'))?_c('span',{staticClass:"font-12px error--text ml-2"},[_vm._v("必須")]):_vm._e()]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [(field.multiple)?[_c('v-select',{staticClass:"font-14px mt-1",attrs:{"outlined":"","dense":"","color":"#13ABA3","error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"items":field.items,"item-text":field.item_text,"item-value":[field.item_value],"multiple":field.multiple,"placeholder":field.placeholder,"no-data-text":field.no_data_text},scopedSlots:_vm._u([(field.append_outer_text)?{key:"append-outer",fn:function(){return [_c('span',{staticClass:"text-no-wrap font-14px"},[_vm._v(" "+_vm._s(field.append_outer_text)+" ")])]},proxy:true}:null],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]:[_c('v-select',{staticClass:"font-14px mt-1",attrs:{"outlined":"","dense":"","color":"#13ABA3","error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"items":field.items,"item-text":field.item_text,"item-value":field.item_value,"placeholder":field.placeholder,"no-data-text":field.no_data_text},scopedSlots:_vm._u([(field.append_outer_text)?{key:"append-outer",fn:function(){return [_c('span',{staticClass:"text-no-wrap font-14px"},[_vm._v(" "+_vm._s(field.append_outer_text)+" ")])]},proxy:true}:null],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]]}}],null,true)})],1)]:_vm._e(),(field.type == 'autocomplete')?[_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-14px"},[_vm._v(" "+_vm._s(field.label)+" "),(field.rules && field.rules.includes('required'))?_c('span',{staticClass:"font-12px error--text ml-2"},[_vm._v("必須")]):_vm._e()]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"font-16px mt-1",attrs:{"outlined":"","dense":"","color":"#13ABA3","error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"search-input":field.searched_text,"loading":field.is_loading,"hide-no-data":"","hide-selected":"","items":field.items,"item-text":field.item_text,"item-value":field.item_value,"placeholder":field.placeholder},on:{"update:searchInput":function($event){return _vm.$set(field, "searched_text", $event)},"update:search-input":function($event){return _vm.$set(field, "searched_text", $event)},"keyup":function($event){field.searchable ? field.search_api(field) : false}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var attrs = ref.attrs;
                  var item = ref.item;
return [_c('div',_vm._b({staticClass:"mt-1"},'div',attrs,false),[_c('span',{staticClass:"font-18px"},[_vm._v(_vm._s(_vm.getItem(item))+" ")]),_c('span',{staticClass:"text-b8b8b8"},[_vm._v(_vm._s(_vm.getBusinessIndustry(item)))])])]}},{key:"selection",fn:function(ref){
                  var attrs = ref.attrs;
                  var item = ref.item;
                  var selected = ref.selected;
return [_c('div',_vm._b({staticClass:"mt-1",attrs:{"input-value":selected}},'div',attrs,false),[_c('span',{staticClass:"font-18px"},[_vm._v(_vm._s(_vm.getItem(item))+" ")]),_c('span',{staticClass:"text-b8b8b8"},[_vm._v(_vm._s(_vm.getBusinessIndustry(item)))])])]}}],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:_vm._e(),(field.type == 'richbox')?[_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-14px mb-2"},[_vm._v(" "+_vm._s(field.label)+" "),(field.rules && field.rules.includes('required'))?_c('span',{staticClass:"font-12px error--text ml-2"},[_vm._v("必須")]):_vm._e()]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('rich-text-editor',{attrs:{"error-messages":errors,"data":field.value,"height":_vm.editorHeight,"headings":_vm.headings ? _vm.headings : {}},on:{"update:data":function($event){return _vm.$set(field, "value", $event)}}}),(errors && errors.length > 0)?[_c('span',{staticClass:"font-12px text-red"},[_vm._v(_vm._s(errors[0]))])]:_vm._e()]}}],null,true)})],1)]:_vm._e()],2),(field.additional_field)?[(
              field.additional_field.visibility_check &&
              field.value == field.additional_field.visible_value
                ? false
                : true
            )?_c('v-col',{staticClass:"mt-1",class:field.additional_field.col_class_right,attrs:{"cols":"12","md":"6"}},[[_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-14px visibility-hidden"},[_vm._v(" "+_vm._s(field.label)+" "),(field.rules && field.rules.includes('required'))?_c('span',{staticClass:"font-12px error--text ml-2"},[_vm._v("必須")]):_vm._e()]),_c('div',{staticClass:"d-flex full-width"},[(field.additional_field.relative_text)?_c('div',{staticClass:"d-flex align-center mr-1"},[_vm._v(" ～ ")]):_vm._e(),_c('validation-provider',{staticClass:"full-width",attrs:{"name":field.additional_field.name,"rules":field.additional_field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(field.additional_field.type == 'text')?[_c('v-text-field',{staticClass:"font-16px mt-1",attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"single-line":"","color":"#13ABA3","outlined":"","dense":"","placeholder":field.additional_field.placeholder},model:{value:(field.additional_field.value),callback:function ($$v) {_vm.$set(field.additional_field, "value", $$v)},expression:"field.additional_field.value"}})]:_vm._e(),(field.additional_field.type == 'dropdown')?[_c('v-select',{staticClass:"mt-1",attrs:{"outlined":"","dense":"","single-line":"","placeholder":field.additional_field.placeholder,"items":field.items,"item-text":field.item_text,"item-value":field.item_value,"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"autocomplete":"chrome-off"},scopedSlots:_vm._u([(field.append_outer_text)?{key:"append-outer",fn:function(){return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(field.append_outer_text)+" ")])]},proxy:true}:null],null,true),model:{value:(field.additional_field.value),callback:function ($$v) {_vm.$set(field.additional_field, "value", $$v)},expression:"field.additional_field.value"}})]:_vm._e()]}}],null,true)})],1)])]],2):_vm._e()]:_vm._e()],2)})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }